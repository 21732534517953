<template>
    <CustomHeader stars>
        <img alt="Raketa" src="@/assets/raketa_bez_loga.svg">

        <h2>{{ top ?? "Soutěž v programování" }}</h2>
        <div class="line"/>
        <p class="heading">
            {{ bottom ?? "pro studenty a studentky středních škol" }}
        </p>

        <div class="buttons">
            <!-- router-tag="button" does not work for some reason, using class instead -->
            <b-button :to="{ path: '/pravidla/' }" class="button" variant="outline-light">
                Více informací
            </b-button>

            <RegisterButton/>
            <b-button v-if="$store.state.isLoggedIn" variant="primary" :to="{ path: '/soutez' }">
                Soutěžit
            </b-button>
        </div>
    </CustomHeader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HeaderComponent',
    props: {
        top: {
            type: String,
            default: null,
        },
        bottom: {
            type: String,
            default: null,
        },
    },
});
</script>

<style scoped>
h2 {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

p {
    color: #af7ae4;
    font-size: 2rem;
    text-align: center;
}

img {
    margin: 1rem 0 1.5rem;
}

.line {
    background-color: #ff0052;

    width: 8rem;
    height: 2px;

    margin: 0.8rem 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.buttons :deep(button),
.buttons :deep(a) {
    padding: 1rem 1.2rem;
}
</style>
